<template>
    <div copy-button @click="copyToClipboard">
        <v-icon x-small>mdi-content-copy</v-icon>
    </div>
</template>

<script>
export default {
    name: 'CopyButton',
    props: {
        value: {
            type: String,
        },
    },
    methods: {
        copyToClipboard () {
            navigator.clipboard.writeText(this.value);
            this.$toast('success', 'Valeur copiée dans le presse-papier 📋');
        },
    },
};
</script>

<style lang="scss">
[copy-button] {
    display: inline-flex;
    cursor: pointer;
    border: 1px solid #ccc;
    aspect-ratio: 1 / 1;
    height: 1.2rem;
    justify-content: center;
    align-items: center;
    border-radius: .3rem;
    color: #ccc;
}
</style>
