<template>
    <section
        class="coring-campaign-orders-popup tw-z-[1]"
        @click="$event.stopPropagation()"
    >
        <v-card
            flat
            outlined
            class="coring-campaign-orders-popup__card"
        >
            <v-btn class="popup__close" icon @click="closePopin">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-title
                class="center justify-center tw-mb-[10px]"
                align-items
            >
                Prestations de la campagne
            </v-card-title>

            <v-card-subtitle
                v-if="campaign.provider === 'AREIA'"
                align-items
                class="tw-mt-[10px] tw-flex justify-center"
            >
                <p>
                    Vous devrez impérativement supprimer la ligne de commande de la plateforme AREIA avant de réaliser un nouvel envoi.<br />
                    Si la commande n'est pas annulée, le prochain envoi <strong>ne fonctionnera pas !</strong>
                </p>
            </v-card-subtitle>

            <v-card-text>
                <table class="tw-table-auto tw-border tw-border-gray-300 tw-w-full tw-mx-auto tw-text-left analyses">
                    <thead>
                        <tr>
                            <th class="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-text-center">
                                Commande
                            </th>
                            <th class="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-text-center">
                                Analyses demandées
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(analysis, index) in analyses"
                            :key="index"
                            class="tw-border tw-border-gray-300 analysis"
                        >
                            <td class="tw-border tw-border-gray-300">
                                <dl class="analysis-metadata">
                                    <template v-if="hasMainOrder(analysis)">
                                        <dt>Commande parente :</dt>
                                        <dd>{{ getMainOrder(analysis).order_number }}</dd>
                                    </template>
                                    <dt>Analyse numéro :</dt>
                                    <dd>{{ analysis.number }}</dd>
                                    <dt>Prestataire :</dt>
                                    <dd>{{ analysis.provider }}</dd>
                                    <dt>Réf. commande prestataire :</dt>
                                    <dd>{{ analysis.order_number }}</dd>
                                    <dt>Réf. Nextroad :</dt>
                                    <dd>{{ analysis.nextroad_reference }}</dd>
                                    <dt>Réf. BDC pour laboratoire :</dt>
                                    <dd>
                                        {{ analysis.customer_reference }}
                                        <copy-button :value="analysis.customer_reference" />
                                    </dd>
                                    <dt>Code campagne :</dt>
                                    <dd>
                                        {{ analysis.business_id }}
                                        <copy-button :value="analysis.business_id" />
                                    </dd>
                                    <dt v-if="analysis.status">Statut :</dt>
                                    <dd v-if="analysis.status">{{ analysis.status }}</dd>
                                    <dt>Date d'envoi :</dt>
                                    <dd>{{ moment(analysis.sent_at).format('DD/MM/YYYY') }}</dd>
                                </dl>

                                <ButtonSlot
                                    @click="refreshData(analysis.id)"
                                    class="tw-mt-[5px]"
                                    _icon="mdi-download-box"
                                    _small
                                >
                                    Récupèrer les expertises
                                </ButtonSlot>

                                <ButtonSlot
                                    v-if="campaign.status !== 'results_obtained'"
                                    @click="cancelPrestation(analysis.id)"
                                    class="tw-mt-[5px]"
                                    _icon="mdi-trash-can"
                                    _small
                                >
                                    Annuler le BDC
                                </ButtonSlot>
                            </td>

                            <td class="tw-border tw-border-gray-300 tw-p-0">
                                <div class="tw-border-solid tw-border-0 tw-border-b tw-border-gray-300 tw-p-1" v-html="prestationsCountLabel(analysis)" />
                                <div class="tw-px-4 tw-py-2 tw-max-h-[350px] tw-overflow-y-auto">
                                    <div
                                        v-for="(core, coreIndex) in analysis.cores"
                                        :key="coreIndex"
                                        class="core-wrapper"
                                    >
                                        <div class="core-number">Carotte {{ core.number }}:</div>
                                        <div class="core-layers">
                                            <div class="core-groupements" :style="`--last-line: ${core.layers.length + 1}`">
                                                <div
                                                    v-for="(groupement, groupementNumber) in groupementsPerCoreId[core.id]"
                                                    class="core-groupement"
                                                    :style="`--groupement-from: ${groupement.from}; --groupement-to: ${groupement.to + 1};`"
                                                >{{ groupementNumber }}</div>
                                            </div>
                                            <div
                                                v-for="(coreLayer, coreLayerIndex) in core.layers"
                                                :key="coreLayerIndex"
                                                class="core-layer"
                                            >
                                                <div class="core-layer-measurements">
                                                    <span
                                                        v-if="0 === coreLayerIndex"
                                                        class="core-layer-measurement core-layer-measurement__start"
                                                    >{{ formatMmMeasure(0) }}</span>
                                                    <span
                                                        class="core-layer-measurement core-layer-measurement__end"
                                                    >{{ formatMmMeasure(coreLayer.depth + coreLayer.length) }}</span>
                                                </div>
                                                <div class="core-layer__number">{{ coreLayer.number }}</div>
                                                <div class="core-layer-prestations">
                                                    <div
                                                        v-for="(prestation, prestationIndex) in coreLayer.prestations"
                                                        :key="prestationIndex"
                                                        class="core-layer-prestation"
                                                        :class="{
                                                            'core-layer-prestation--am': prestation.type === 'ASBESTOS',
                                                            'core-layer-prestation--hap': prestation.type === 'PAH',
                                                            'core-layer-prestation--hct': prestation.type === 'TH',
                                                        }"
                                                    >
                                                        <b>{{ getLabelForPrestationType(prestation.type) }}:</b> {{ prestation.name }}
                                                    </div>
                                                    <div
                                                        v-if="coreLayer.prestations.length === 0"
                                                    >Aucune prestation pour cette couche</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-card-text>
        </v-card>
    </section>
</template>

<script>
import CopyButton from '@/components/utils/CopyButton.vue';
export default {
    name: 'CampaignPrestationsModal',
    components: {
        'copy-button': CopyButton,
    },
    props: {
        campaign: {
            default: false,
        },
    },
    data () {
        return {
            analyses: [],
        };
    },
    computed: {
        groupementsPerCoreId () {
            const groupementsPerCoreId = {};

            this.analyses.forEach((analysis) => {
                analysis.cores.forEach((core) => {
                    const groupements = core.layers.reduce((acc, layer) => {
                        if (null === layer.groupement) {
                            return acc;
                        }

                        if (!acc[layer.groupement]) {
                            acc[layer.groupement] = {
                                from: layer.number,
                                to: layer.number,
                            };
                        }

                        acc[layer.groupement] = {
                            from: Math.min(acc[layer.groupement].from, layer.number),
                            to: Math.max(acc[layer.groupement].from, layer.number),
                        };

                        return acc;
                    }, {});

                    if (Object.keys(groupements).length > 0) {
                        groupementsPerCoreId[core.id] = groupements;
                    }
                });
            });

            return groupementsPerCoreId;
        },
    },
    methods: {
        closePopin () {
            this.$emit('closeModale');
        },
        async refreshData (analyseId) {
            await this.$api.coringCampaigns.getFromProvider(this.campaign.id, false, analyseId);
        },
        async loadAnalyses (campaignId) {
            this.analyses = (await this.$api.campaignAnalyse.getAnalysesForCampaign(campaignId)).analyses;
        },
        getLabelForPrestationType (type) {
            return {
                'ASBESTOS': 'AM',
                'PAH': 'HAP',
                'TH': 'HCT',
            }[type];
        },
        formatMmMeasure (measureInMm) {
            return `${new Intl.NumberFormat('fr-FR').format(measureInMm / 10)} cm`;
        },
        async cancelPrestation ( analysisId ) {
            this.$api.campaignAnalyse.cancelAnalysis(analysisId).then(() => {
                this.$emit('closeModale');
            });
        },
        hasMainOrder (analysis) {
            return this.analyses.some((mainAnalysis) => mainAnalysis.linked_analysis === analysis.id);
        },
        getMainOrder (analysis) {
            return this.analyses.find((mainAnalysis) => mainAnalysis.linked_analysis === analysis.id);
        },
        countCorePrestationsForType (core, type) {
            const layersByGroup = core.layers.reduce((acc, layer) => {
                if (!layer.groupement) {
                    return acc;
                }

                acc[layer.groupement] ??= [];
                acc[layer.groupement].push(layer);

                return acc;
            }, {});

            const layersPrestationsCodesExtractor = (acc, layer) => {
                return [
                    ...acc,
                    ...layer.prestations
                        .filter((prestation) => type === prestation.type)
                        .map(prestation => prestation.code),
                ];
            };

            const uniquePrestationsByGroupementForType = Object.values(layersByGroup).map((groupement) => {
                const prestationCodes = groupement.reduce(layersPrestationsCodesExtractor, []);

                return [...(new Set(prestationCodes))];
            }).reduce((acc, groupementUniqueCodes) => {
                acc.push(...groupementUniqueCodes);

                return acc;
            }, []);

            const uniquePrestationsForType = core.layers.filter((layer) => null === layer.groupement).reduce(layersPrestationsCodesExtractor, []);

            return uniquePrestationsByGroupementForType.length + uniquePrestationsForType.length;
        },
        getPrestationsCountByType (analysis, type) {
            return analysis.cores.map(
                (core) => this.countCorePrestationsForType(core, type),
            ).reduce((acc, count) => {
                return acc + count;
            }, 0);
        },
        prestationsCountLabel (analysis) {
            const asbestosPrestationsCount = this.getPrestationsCountByType(analysis, 'ASBESTOS');
            const pahPrestationsCount = this.getPrestationsCountByType(analysis, 'PAH');
            const thPrestationsCount = this.getPrestationsCountByType(analysis, 'TH');

            const totalPrestationsCount = asbestosPrestationsCount + pahPrestationsCount + thPrestationsCount;

            return `<b>${asbestosPrestationsCount}</b> prestation${asbestosPrestationsCount === 1 ? '' : 's'} amiante,
                <b>${pahPrestationsCount}</b> prestation${pahPrestationsCount === 1 ? '' : 's'} HAP
                et <b>${thPrestationsCount}</b> prestation${thPrestationsCount === 1 ? '' : 's'} HCT
                (<b>${totalPrestationsCount}</b> prestation${totalPrestationsCount === 1 ? '' : 's'} au total)`;
        },
    },
    mounted () {
        this.$root.$on('closeModale', this.closePopin);

        this.loadAnalyses(this.campaign.id);
    },
};

</script>

<style lang="scss">
@import "@/assets/scss/_extends.scss";
.coring-campaign-orders-popup {
  @extend %popinView;

    cursor: auto;

  .table {
    th {
      text-align: center !important;
    }
  }

  .v-card {
    width: 60%;
    text-align: center;
    padding: 20px 20px;
    max-height: 90%;
    overflow: auto;
  }

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .v-input--selection-controls {
	margin-top: 0px !important;
  }

  // Styles personnalisés pour le tableau
  table {
    width: 100%;
    border-collapse: collapse;
    margin: auto;
    text-align: left;

    th, td {
      border: 1px solid #ccc; // Couleur de bordure
      padding: 10px;
    }

    th {
      background-color: #f9f9f9; // Couleur de fond pour les en-têtes
      text-align: center;
    }
  }
}

.analysis {
    .core-wrapper {
        .core {
            &-number {
                padding-left: 1em;
                font-style: italic;
                border-bottom: 1px solid #ccc;
                margin-bottom: 1em;
                grid-column: 1 / -1;
            }

            &-structure {
                display: flex;
                flex-direction: column;
                margin-block: .5em;
            }

            &-layers {
                display: grid;
                grid-template-columns: 2em 4em 30px 1fr;
                margin-block-start: .5em;
                margin-block-end: 1em;
            }

            &-groupements {
                display: grid;
                grid-template-rows: subgrid;
                grid-row: 1 / var(--last-line);
            }

            &-groupement {
                grid-row: var(--groupement-from) / var(--groupement-to);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 1px solid #ccc;
                border-left-width: 3px;
                border-right: none;
                border-radius: .5em 0 0 .5em;
                font-weight: bold;
            }

            &-layer {
                display: grid;
                grid-template-columns: subgrid;
                grid-column: 2 / -1;
                min-height: 3em;

                &__number {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    border: 1px solid #ccc;
                }

                &:not(:last-child) {
                    .core-layer {
                        &__number {
                            border-bottom: 2px solid #ccc;
                        }
                    }
                }

                &:nth-of-type(2) {
                    .core-layer {
                        &__number {
                            border-top-left-radius: .5em;
                            border-top-right-radius: .5em;
                        }
                    }
                }

                &:last-child {
                    .core-layer {
                        &__number {
                            border-bottom-left-radius: .5em;
                            border-bottom-right-radius: .5em;
                        }
                    }
                }

                &-measurements {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    padding-right: .5em;
                    justify-content: flex-end;
                    position: relative;
                }

                &-measurement {
                    border: 1px solid #ccc;
                    font-size: .7em;
                    line-height: 1.3em;
                    text-align: right;
                    text-wrap: nowrap;
                    border-radius: .5em;
                    padding-inline: .2em;

                    &__start {
                        transform: translateY(-50%);
                        top: 0;
                        position: absolute;
                    }

                    &__end {
                        transform: translateY(50%);
                    }
                }

                &-prestations {
                    display: grid;
                    gap: .5em;
                    align-content: center;
                    padding-left: 1em;
                    padding-block: .5em;
                }

                &-prestation {
                    border: 1px solid #cccccc;
                    border-radius: .3em;
                    padding: 0 .2em;
                    background-color: #ececec;
                    position: relative;
                    text-wrap: nowrap;
                    font-size: .8em;
                    line-height: 1.2em;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 1.65em;
                        height: 2px;
                        background-color: #cccccc;
                        left: -1.65em;
                        top: 50%;
                    }

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: #cccccc;
                        left: -1.65em;
                        top: 50%;
                        transform: translateY(calc(-50% + 1px));
                    }

                    &--am {
                        background-color: #ffb5b5;
                        border-color: #ca5454;

                        &:before, &:after {
                            background-color: #ca5454;
                        }
                    }

                    &--hap {
                        background-color: #bcffbf;
                        border-color: #6eb86b;

                        &:before, &:after {
                            background-color: #6eb86b;
                        }
                    }

                    &--hct {
                        background-color: #ffd79a;
                        border-color: #e88d02;

                        &:before, &:after {
                            background-color: #e88d02;
                        }
                    }
                }
            }
        }
    }

    &-actions {
        button {
        }
    }
}

.analysis-metadata {
    dt {
        font-weight: bold;
        font-style: italic;
        font-size: .9em;
        line-height: 1em;
    }

    dd {
        padding-left: 1em;
        user-select: text;
        display: flex;
        justify-content: space-between;
    }
}
</style>
