<template>
    <div
        class="tw-flex tw-justify-between tw-p-[10px] tw-items-start tw-cursor-pointer"
        campaign-card
        @click="redirectToCampaignView"
        @auxclick="handleAuxClick"
    >
        <div class="tw-flex tw-flex-col tw-gap-[5px] tw-overflow-hidden tw-h-full tw-justify-between">
            <div class="tw-flex tw-flex-row">
                <h2
                    class="majFL tw-whitespace-nowrap tw-text-ellipsis tw-overflow-x-hidden lineHeight tw-mr-[5px]"
                >
                    {{ campaign.name }}
                </h2>

                <div
                    class="business tw-flex tw-flex-row tw-items-center"
                    v-if="campaign.workspace.site.organization.id==='6e42295a-a6e4-43b8-84d9-d37c5d9db7b3' && campaign.type === 'coring' && campaign.displayNamePrefix.length !== 0"
                >
                    <span
                        v-for="(prefix, index) in campaign.displayNamePrefix"
                    >
                        {{ index === 0 ? 'Réf. campagne :' : '' }}
                        &nbsp;{{ index === 0 ? prefix.name : prefix.name + (index !== campaign.displayNamePrefix.length - 1 ? ' |' : '') }}

                        {{ index === 0 && index !== campaign.displayNamePrefix.length -1 ? ' / Réf. carottes : ' : '' }}
                    </span>
                </div>
            </div>

            <div
                class="tw-flex tw-flex-start"
                v-if="$hasRight('globalActions.superAdmin') || false"
            >
                <span
                    class="business"
                >
                    Business ID : {{ campaign.business }}
                </span>
            </div>

            <div class="tw-flex tw-gap-[10px] tw-flex-wrap">
                <v-chip
                    v-if="campaign.type === 'coring'"
                    small
                >
                    <b class="majFL">
                        {{ getCoringType }}
                    </b>
                </v-chip>

                <v-chip
                    v-else
                    small
                >
                    <b>{{ $t(campaign.type) }}</b>
                </v-chip>

                <v-chip
                    small
                    v-if="
                        campaign.type === 'auscultation' &&
                            campaign.operatingMode !== 'not_standard' &&
                            campaign.operatingMode !== null
                    "
                >
                    <b>{{ $t(campaign.operatingMode) }}</b>
                </v-chip>

                <v-chip
                    v-if="campaign.type === 'coring' && null !== campaign.startDate"
                    small
                >
                    <b>{{ countAndDisplayCampaignDate }}</b>
                </v-chip>

                <v-chip
                    v-if="campaign.type === 'auscultation' && null !== campaign.endDate"
                    small
                >
                    <b>{{ moment(campaign.endDate).format("Do/MM/YYYY") }}</b>
                </v-chip>

                <v-tooltip
                    v-if="'coring' === campaign.type"
                    top
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            small
                            v-bind="attrs"
                            v-on="on"
                        >
                            <b>{{ manageCoresCount }}</b>
                        </v-chip>
                    </template>
                    <span
                        v-html="getCoreList()"
                    />
                </v-tooltip>

                <v-tooltip
                    v-if="'coring' === campaign.type && campaign.campaignAnalyse.length !== 0"
                    top
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            small
                            v-bind="attrs"
                            v-on="on"
                        >
                            <b>Nombre BDC : {{ campaign.campaignAnalyse.length }}</b>
                        </v-chip>
                    </template>
                    <span
                        v-html="getCampaignAnalyseList()"
                    />
                </v-tooltip>

                <v-chip
                    small
                    v-show="isNewChipDisplayed"
                    color="blue"
                    outlined
                >
                    {{ $t("new") }}
                </v-chip>

                <v-chip
                    v-if="campaign.endDate !== null && isToLate"
                    small
                    color="red"
                    outlined
                >
                    <b>Date dépassée</b>
                </v-chip>

                <v-chip
                    small
                    v-else-if="campaign.endDate !== null && isUrgent"
                    color="red"
                    outlined
                >
                    Urgent
                </v-chip>

                <v-chip
                    small
                    v-show="campaign.usersLinkedCount === 0"
                    color="red"
                    outlined
                >
                    Equipe projet non renseignée
                </v-chip>

                <v-chip
                    small
                    v-if="campaign.archived"
                    color="red"
                    outlined
                >
                    Archivée
                </v-chip>

                <v-chip
                    small
                    v-if="campaign.deliveryCount > 0"
                >
                    {{ campaign.deliveryCount }} livrable{{ campaign.deliveryCount > 1 ? 's' : '' }}
                </v-chip>

                <v-chip
                    small
                    v-if="currentStatusInfo.step > 4 && campaign.provider !== null && campaign.type === 'coring'"
                >
                    {{ campaign.provider }}
                </v-chip>

                <v-chip
                    small
                    v-if="campaign.customerOrderRef !== null"
                >
                    {{ campaign.customerOrderRef }}
                </v-chip>
            </div>
        </div>

        <div class="tw-flex tw-items-start tw-gap-[12px] tw-h-full tw-cursor-default">
            <div
                @click="$event.stopPropagation()"
                v-if="editMode === false"
                class="tw-flex tw-flex-col tw-h-full tw-justify-evenly tw-items-end"
            >
                <div class="tw-w-[112px] lg:tw-w-auto tw-gap-[12px] tw-flex tw-flex-wrap lg:tw-flex-nowrap">
                    <v-tooltip
                        v-for="[index, value] of listStatus"
                        top
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <span
                                v-if="value.step !== -1"
                                class="tw-h-[15px] tw-rounded-[4px] tw-flex justify-center"
                                :class="{
                                    'tw-bg-[#ebebeb]': currentStatusInfo.step < value.step && currentStatusInfo.step !== lastStep,
                                    'tw-bg-[#ff9c42]': currentStatusInfo.step > value.step && currentStatusInfo.step !== lastStep,
                                    'tw-bg-[#ed7507]': (currentStatusInfo.step === value.step && currentStatusInfo.step !== lastStep) || value.step === 1,
                                    'tw-bg-[green]': currentStatusInfo.step === lastStep,
                                    'tw-w-[50px]': listStatus.length === 6,
                                    'tw-w-[81px]': listStatus.length === 4
                                }"
                                v-bind="attrs"
                                v-on="on"
                            />
                        </template>
                        <span
                            v-if="'coring' === campaign.type && currentStatusInfo.step === value.step"
                            v-html="getCoreList()"
                        />
                        <span v-else>
                            {{ value.label }}
                        </span>
                    </v-tooltip>
                </div>

                <span class="tw-min-w-[112px] tw-self-start tw-gap-[5px] tw-flex tw-flex-row tw-max-w-[350px] tw-flex-wrap">
                    {{ listStatus.find(e => e[0] == campaign.status)[1].label }}

                    <div
                        class="tw-italic tw-text-[12px] tw-self-center"
                        v-if="currentStatusInfo.step === 5 && campaign.type === 'coring'"
                    >
                        <span> - Demandée le {{ moment(getLatestSentToProvider()).format('D/MM/Y') }}</span>
                    </div>

                    <div
                        class="tw-italic tw-text-[12px] tw-self-center"
                        v-if="currentStatusInfo.step === 1 && campaign.type === 'coring' && campaign.shouldValidateCustomer"
                    >
                        <span
                            v-if="$hasRight('users.isCustomer') && !campaign.isValidatedCustomer"
                            style="color: red"
                        >
                            - Attente validation
                        </span>
                        <span
                            v-else-if="$hasRight('users.isCustomer') && campaign.isValidatedCustomer"
                            style="color: green"
                        >
                            - Validation effectuée
                        </span>
                        <span
                            v-else-if="$hasRight('campaigns.editCampaignBtn') && !campaign.isValidatedCustomer"
                            style="color: red"
                        >
                            - Attente validation client
                        </span>
                        <span
                            v-else-if="$hasRight('campaigns.editCampaignBtn') && campaign.isValidatedCustomer"
                            style="color: green"
                        >
                            - Validation client effectuée
                        </span>
                    </div>

                    <div
                        class="tw-italic tw-text-[12px] tw-self-center"
                        v-if="currentStatusInfo.step === 2"
                    >
                        <span
                            style="color: green"
                            v-html="getCoreExtractedText()"
                        />
                    </div>

                    <div
                        class="tw-italic tw-text-[12px] tw-self-center"
                        v-if="currentStatusInfo.step > 4 && campaign.asbestosResultCount !== null && campaign.hasAsbestos && campaign.type === 'coring'"
                    >
                        - <span :style="'color:'+getColor(campaign.asbestosResultCount)">
                            {{ campaign.asbestosResultCount }} Amiante
                        </span>
                    </div>

                    <div
                        class="tw-italic tw-text-[12px] tw-self-center"
                        v-if="currentStatusInfo.step > 4 && campaign.pahResultCount !== null && campaign.hasPAH && campaign.type === 'coring'"
                    >
                        - <span :style="'color:'+getColor(campaign.pahResultCount)">
                            {{ campaign.pahResultCount }} HAP
                        </span>
                    </div>

                    <div
                        class="tw-italic tw-text-[12px] tw-self-center"
                        v-if="currentStatusInfo.step > 4 && campaign.thResultCount !== null && campaign.hasTH && campaign.type === 'coring'"
                    >
                        - <span :style="'color:'+getColor(campaign.pahResultCount)">
                            {{ campaign.thResultCount }} HCT
                        </span>
                    </div>


                </span>
            </div>

            <div
                @click="$event.stopPropagation()"
                class="tw-w-[200px] tw-grid tw-grid-cols-2 tw-gap-x-[5px] tw-gap-y-[2px]"
                v-else
            >
                <v-select
                    class="tw-col-span-2"
                    dense
                    :items="listStatusChange"
                    label="Statut"
                    outlined
                    :item-text="(value) => value[1].label"
                    :item-value="(value) => value[0]"
                    v-model="status"
                    hide-details
                />

                <ButtonSlot
                    @click="updateStatus"
                    _icon="mdi-check"
                    _small
                    class="tw-col-span-1"
                />

                <ButtonSlot
                    _theme="light-gray"
                    _icon="mdi-close"
                    _small
                    class="tw-col-span-1"
                    @click="$event.stopPropagation(); editMode = false"
                />
            </div>

            <div class="tw-flex tw-flex-col tw-justify-between tw-h-full">
                <v-menu v-if="$hasRight('campaigns.editCampaignBtn')">
                    <template v-slot:activator="{ on, attrs }">
                        <ButtonSlot
                            _icon="mdi-dots-horizontal"
                            _icon-size="25px"
                            _theme="none"
                            _full-rounded
                            v-bind="attrs"
                            v-on="on"
                            @click="$event.stopPropagation();"
                        />
                    </template>

                    <v-list
                        class="pa-0"
                        width="25vh"
                    >
                        <v-list-item
                            :to="{ name: 'manage-campaign', params: { id: campaign.id } }"
                        >
                            <v-list-item-title>Éditer</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            v-if="($hasRight('workspaces.editBtn')) || $hasRight('users.isSuperAdmin')"
                            @click="editMode = true"
                        >
                            <v-list-item-title>Modifier le statut</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            @click.stop="
                                campaign.status === 'awaiting_validation' ||
                                    campaign.status === 'programing_validated'
                                    ? () => {}
                                    : redirectToPrestationSettings()
                            "
                            v-if="
                                $hasRight('campaigns.editCampaignBtn') &&
                                    $hasRight('campaigns.sendToProvider') &&
                                    currentStatusInfo.step > 3 && campaign.type === 'coring'
                            "
                        >
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title
                                        @click="() => {}"
                                        v-on="on"
                                        v-bind="attrs"
                                    >
                                        Accéder aux prestations du BDC
                                    </v-list-item-title>
                                </template>

                                <span>Paramétrage couche par couche des prestations</span>
                            </v-tooltip>
                        </v-list-item>

                        <v-list-item
                            @click="cancelDialog = true"
                            v-if="currentStatusInfo.step >= 5 && campaign.type === 'coring' && $hasRight('campaigns.sendToProvider')"
                        >
                            <v-list-item-title>Acceder / Annuler BDC</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            @click="setNewAnalyse()"
                            v-if="(campaign.status === 'results_obtained' || campaign.status === 'sent_for_analysis') && campaign.hasNotSentCore === true"
                        >
                            <v-list-item-title>Ajout prélèvement supplé.</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            @click="archiveCampaign({ isArchived: true })"
                            v-if="!campaign.archived"
                        >
                            <v-list-item-title>Archiver</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            @click="archiveCampaign({ isArchived: false })"
                            v-if="campaign.archived"
                        >
                            <v-list-item-title>Désarchiver</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            @click="deleteDialog = true"
                            v-if="$hasRight('globalActions.deleteCampaignsAndProjects')"
                        >
                            <v-list-item-title class="red-text">
                                Supprimer
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <ButtonSlot
                    _icon="mdi-sync"
                    _icon-size="25px"
                    _theme="none"
                    _tooltip="Récupérer les données du prestataire"
                    _tooltip-dir="left"
                    v-if="campaign.status === 'sent_for_analysis' && $hasRight('campaigns.createCampaignBtn')"
                    @click.stop="refreshData"
                    _small
                />
            </div>
        </div>

        <DeleteCampaignProjetPopup
            v-if="deleteDialog"
            @closeModale="deleteCampaign()"
            :campaignOrProjectId="campaign.id"
            type="campaign"
        />

        <CampaignPrestationsModal
            v-if="cancelDialog"
            @closeModale="cancelPrestation()"
            @validateModale="validateCancelPrestation()"
            :campaign="campaign"
        />
    </div>
</template>

<script>
import CampaignStepBar from './CampaignStepBar.vue';
import { infoStatusAuscultation } from '../../utils/auscultation';
import { infoStatusCoringCA, infoStatusCoringCS, infoStatusCoringCACustomer, infoStatusCoringCSCustomer } from '../../utils/coring';
import DeleteCampaignProjetPopup from '../popup/DeleteCampaignProjet.popup.vue';
import { mapActions } from 'vuex';
import CampaignPrestationsModal from '../popup/CampaignPrestationsModal.vue';

export default {
    name: 'CampaignCard',
    components: { CampaignStepBar, DeleteCampaignProjetPopup, CampaignPrestationsModal },
    props: {
        campaign: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            editMode: false,
            status: false,
            deleteDialog: false,
            cancelDialog: false,
        };
    },
    computed: {
        countAndDisplayCampaignDate () {
            let daysCounter = this.moment(this.campaign.endDate).diff(this.campaign.startDate, 'days') + 1;
            let dayOrDays = daysCounter > 1 ? `${daysCounter} jours` : `${daysCounter} jour`;
            let campaignDateDisplay = `${this.moment(this.campaign.startDate).format(
                'Do/MM/YYYY',
            )} - ${this.moment(this.campaign.endDate).format(
                'Do/MM/YYYY',
            )} (${dayOrDays})`;

            return campaignDateDisplay;
        },

        manageCoresCount () {
            let message = `${this.campaign.coresCount} `;

            const coresTaken = [
                'taken',
                'taking_validated',
                'sent_for_analysis',
                'results_obtained',
                'status_archived',
            ];

            if (0 < this.campaign.coresCount && !coresTaken.includes(this.campaign.status)) {
                message += 1 === this.campaign.coresCount ? 'carotte implantée' : 'carottes implantées';
            } else if (0 < this.campaign.coresCount && coresTaken.includes(this.campaign.status)) {
                message += 1 === this.campaign.coresCount ? 'carotte réalisée' : 'carottes réalisées';
            } else {
                message = 'Carottes non implantées';
            }

            return message;
        },

        listStatus () {
            if (this.campaign.type == 'auscultation') return Object.entries(infoStatusAuscultation).slice(0, -1);
            else if (this.campaign.type === 'coring' && this.campaign.coringType === 'CA' && this.$hasRight('users.isCustomer')  || (this.campaign.coringType === 'CS' && this.campaign.hasAsbestos === true &&  this.$hasRight('users.isCustomer')) || (this.campaign.coringType === 'CS' && this.currentStatusInfo.step < 6 &&  this.$hasRight('users.isCustomer'))) {
                return Object.entries(infoStatusCoringCACustomer).slice(0, -1);
            } else if (this.campaign.coringType === 'CS' && (this.campaign.hasAsbestos === null || this.campaign.hasAsbestos === false)  && this.$hasRight('users.isCustomer')) {
                return Object.entries(infoStatusCoringCSCustomer).slice(0, -1);
            } else if (this.campaign.type === 'coring' && this.campaign.coringType === 'CA'  || (this.campaign.coringType === 'CS' && this.campaign.hasAsbestos === true) || (this.campaign.coringType === 'CS' && this.currentStatusInfo.step < 6)) {
                return Object.entries(infoStatusCoringCA).slice(0, -1);
            } else if (this.campaign.coringType === 'CS' && (this.campaign.hasAsbestos === null || this.campaign.hasAsbestos === false)) {
                return Object.entries(infoStatusCoringCS).slice(0, -1);
            } else return [];
        },

        listStatusChange () {
            if (this.campaign.type == 'auscultation') return Object.entries(infoStatusAuscultation).slice(0, -1);
            else if ((this.campaign.type === 'coring' && this.campaign.coringType === 'CA'  || (this.campaign.coringType === 'CS' && this.campaign.hasAsbestos === true) || (this.campaign.coringType === 'CS' && this.currentStatusInfo.step < 6)) && this.$hasRight('users.isSuperAdmin')) return Object.entries(infoStatusCoringCA).slice(0, -1);
            else if ((this.campaign.type === 'coring' && this.campaign.coringType === 'CA'  || (this.campaign.coringType === 'CS' && this.campaign.hasAsbestos === true) || (this.campaign.coringType === 'CS' && this.currentStatusInfo.step < 6)) && false === this.$hasRight('users.isSuperAdmin')) {
                if (this.campaign.cores.find(e => e.status === 'results_acquired')) {
                    return Object.entries(infoStatusCoringCA).slice(0, -1);
                } else if (this.campaign.cores.find(e => e.status === 'sent_to_provider')) {
                    return Object.entries(infoStatusCoringCA).slice(0, -2);
                } else {
                    return Object.entries(infoStatusCoringCA).slice(0, -3).filter(e => e[1].step < this.currentStatusInfo.step);
                }
            } else if (this.campaign.coringType === 'CS' && (this.campaign.hasAsbestos === null || this.campaign.hasAsbestos === false)) return Object.entries(infoStatusCoringCS).slice(0, -1);
            else return [];
        },

        currentStatusInfo () {
            if (this.campaign !== undefined) {
                if (this.campaign.type == 'auscultation') return infoStatusAuscultation[this.campaign.status];
                else if (this.campaign.type == 'coring' && this.$hasRight('users.isCustomer')) return infoStatusCoringCACustomer[this.campaign.status];
                else if (this.campaign.type == 'coring') return infoStatusCoringCA[this.campaign.status];
            } else return {};
        },

        lastStep () {
            if (this.campaign.type == 'auscultation') return Object.keys(infoStatusAuscultation).length - 1;
            else if (this.campaign.type == 'coring') return Object.keys(infoStatusCoringCA).length - 1;
            else return 0;
        },

        isNewChipDisplayed () {
            let oneWeekAgo = new Date();
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
            return Date.parse(this.campaign.createdAt) > oneWeekAgo;
        },

        isUrgent () {
            let oneWeekAgo = new Date(this.campaign.endDate);
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
            return oneWeekAgo.getTime() < Date.now() && this.currentStatusInfo.step < this.lastStep;
        },

        isToLate () {
            return Date.parse(this.campaign.endDate) < Date.now() && this.currentStatusInfo.step < this.lastStep;
        },

        getCoringType () {
            let hasAsbestos = false;
            let hasPAH = false;
            let hasTH = false;
            let sentence = '';
            if (this.campaign.coringType === 'CA') {
                if (this.currentStatusInfo.step > 4) {
                    hasAsbestos = this.campaign.hasAsbestos;
                    hasPAH = this.campaign.hasPAH;
                    hasTH = this.campaign.hasTH;

                    sentence = hasAsbestos !== null && hasAsbestos !== false ? 'Amiante' : '';
                    sentence += hasPAH !== null && hasPAH !== false ? ', HAP' : '';
                    sentence += hasTH !== null && hasTH !== false ? ', HCT' : '';

                    return sentence;
                } else {
                    return 'Amiante, HAP, HCT';
                }
            } else if (this.campaign.coringType === 'CS') {
                if (this.currentStatusInfo.step > 4) {
                    if (this.campaign.hasAsbestos || this.campaign.hasPAH || this.campaign.hasTH) {
                        hasAsbestos = this.campaign.hasAsbestos;
                        hasPAH = this.campaign.hasPAH;
                        hasTH = this.campaign.hasTH;

                        sentence = hasAsbestos ? 'Amiante' : '';
                        sentence += hasPAH ? ', HAP' : '';
                        sentence += hasTH ? ', HCT' : '';
                        return 'Structurel (option ' + sentence + ')';
                    } else {
                        return 'Structurel';
                    }
                } else {
                    return 'Structurel (option Amiante, HAP, HCT)';
                }
            }
        },
    },
    watch: {
        editMode () {
            if (this.editMode === true) this.status = this.campaign.status;
            else this.status = false;
        },
    },
    methods: {
        ...mapActions('project', [
            'initStore', 'purgeStore', 'updateCampaign', 'updateCampaignData',
        ]),

        archiveCampaign (archived) {
            const action = archived.isArchived === true ? 'archiver' : 'désarchiver';
            this.$toast(
                'info',
                'Êtes vous sûr de vouloir ' + action + ' la campagne ?',
                action,
                async () => {
                    const campaign = await this.$api.campaigns.editArchived(
                        this.campaign.id,
                        archived.isArchived,
                    );

                    //await this.updateCampaign(this.campaign.id);
                    this.$emit('archiveCampaign');
                },
            );
        },
        redirectToCampaignView () {
            if (this.campaign.type === 'auscultation') {
                this.$router.push({
                    name: 'auscultation-campaign-dashboard',
                    params: { id: this.campaign.id },
                });
            } else {
                let routeName = 'coring-campaign';
                if (this.campaign.status === 'awaiting_validation' && this.$hasRight('users.isMainCustomer')) {
                    this.$router.push({
                        name: routeName,
                        params: { id: this.campaign.id },
                    });
                } else if (
                    (!this.$hasRight('globalActions.accessDashboardNotFinished') && this.campaign.status !== 'results_obtained') ||
					(this.campaign.status === 'results_obtained' && this.campaign.isAccessibleCustomer && !this.$hasRight('globalActions.accessDashboardNotFinished'))
                ) {
                    this.$toast('warning', 'Vous n\'avez pas encore accès a cette campagne');
                    return;
                }

                if (this.campaign.status === 'results_obtained' || (this.campaign.status === 'sent_for_analysis' && this.campaign.asbestosResultCount !== null) || (this.campaign.status === 'sent_for_analysis' && this.campaign.pahResultCount !== null)) {
                    routeName += '-dashboard';
                }

                this.$router.push({
                    name: routeName,
                    params: { id: this.campaign.id },
                });
            }
        },

        async refreshData () {
            await this.$api.coringCampaigns.getFromProvider(this.campaign.id);
            this.updateCampaign(this.campaign.id);
        },

        redirectToPrestationSettings () {
            this.$router.push({
                name: 'coring-campaign-prestations',
                params: { id: this.campaign.id },
            });
        },

        updateStatus () {
            if (this.campaign.type === 'coring') {
                this.$api.coringCampaigns.editStatus(this.campaign.id, this.status).then(campaign => {
                    this.updateCampaignData(campaign);
                    this.$emit('refreshCampaigns');
                    this.editMode = false;
                });
            } else {
                this.$api.auscultationCampaigns.editStatus(this.campaign.id, this.status).then(campaign => {
                    this.updateCampaignData(campaign);
                    this.$emit('refreshCampaigns');
                    this.editMode = false;
                });
            }
            //this.initStore(this.$route.params.id);
        },

        deleteCampaign () {
            this.deleteDialog = false;
            this.$emit('refreshCampaignsDelete');
        },

        cancelPrestation () {
            this.cancelDialog = false;
        },

        validateCancelPrestation () {
            this.cancelDialog = false;
            this.$emit('refreshCampaignsDelete');
        },

        setNewAnalyse () {
            this.status = 'taking_validated';
            this.updateStatus();
        },
        handleAuxClick (event) {
            // Si le clic est fait avec le bouton du milieu ou avec Ctrl + clic gauche, ouvre dans un nouvel onglet
            if (event.button === 1 || (event.button === 0 && event.ctrlKey)) {

                if (this.campaign.type === 'auscultation') {
                    window.open(`/auscultation-campaign/${this.campaign.id}/dashboard`, '_blank');
                } else {
                    if (this.campaign.status === 'awaiting_validation' && this.$hasRight('users.isCustomer')) {
                        window.open(`/coring-campaign/${this.campaign.id}`, '_blank');
                    }
                    if (!this.$hasRight('campaigns.accessPrepareCampaign') || this.campaign.status === 'results_obtained' || (this.campaign.status === 'sent_for_analysis' && this.campaign.asbestosResultCount !== null) || (this.campaign.status === 'sent_for_analysis' && this.campaign.pahResultCount !== null)) {
                        window.open(`/coring-campaign/${this.campaign.id}/dashboard`, '_blank');
                    } else {
                        window.open(`/coring-campaign/${this.campaign.id}`, '_blank');
                    }
                }
            }
        },
        getColor (value) {
            let arrayValue = value.split('/');
            if (parseInt(arrayValue[0]) === 0) {
                return '#CF3831';
            } else if (parseInt(arrayValue[0]) !== parseInt(arrayValue[1])) {
                return '#F8A324';
            } else {
                return '#55863A';
            }
        },
        getCoreList () {
            let empty = this.campaign.cores.filter(e => e.status === 'empty').length;
            let waiting = this.campaign.cores.filter(e => e.status === 'waiting').length;
            let extracted = this.campaign.cores.filter(e => e.status === 'extracted').length;
            let sent_to_study_manager = this.campaign.cores.filter(e => e.status === 'sent_to_study_manager').length;
            let validated = this.campaign.cores.filter(e => e.status === 'validated').length;
            let sent_to_provider = this.campaign.cores.filter(e => e.status === 'sent_to_provider').length;
            let results_acquired = this.campaign.cores.filter(e => e.status === 'results_acquired').length;
            let cancelled = this.campaign.cores.filter(e => e.status === 'cancelled').length;

            let text = empty !== 0 ? 'A saisir : ' + empty + '<br>' : '';
            text += waiting !== 0 ? 'En attente : ' + waiting + '<br>' : '';
            text += extracted !== 0 ? 'Extraite : ' + extracted + '<br>' : '';
            text += sent_to_study_manager !== 0 ? 'Envoyée au chargé d\'affaires : ' + sent_to_study_manager + '<br>' : '';
            text += validated !== 0 ? 'Validée : ' + validated + '<br>' : '';
            text += sent_to_provider !== 0 ? 'Envoyée au laboratoire : ' + sent_to_provider + '<br>' : '';
            text += results_acquired !== 0 ? 'Résultats reçus : ' + results_acquired + '<br>' : '';
            text += cancelled !== 0 ? 'A saisir : ' + cancelled + '<br>' : '';

            return text;
        },
        getCoreExtractedText () {
            let extracted = this.campaign.cores.filter(e => e.status === 'extracted' || e.status === 'sent_to_study_manager').length;
            let sent_to_study_manager = this.campaign.cores.filter(e => e.status === 'sent_to_study_manager').length;

            let text = 'Extrait ' + extracted + ' sur ' + this.campaign.cores.length + ' (dont ' + sent_to_study_manager + ' envoyé au CA)';

            return text;
        },

        getCampaignAnalyseList () {
            let text = '';
            this.campaign.campaignAnalyse.forEach((analyse, index) => {
                text += `Analyse ${index + 1} demandée le : ${this.moment(analyse.createdAt).format('DD/MM/YYYY')} : ${this.$t(analyse.orderStatus)} <br>`;
            });

            return text;
        },
        getLatestSentToProvider () {
            let maxDate = null;
            this.campaign.cores.forEach(core => {
                core.statusChange.forEach(change => {
                    if (change.status === 'sent_to_provider') {
                        let currentDate = new Date(change.createdAt);
                        if (maxDate === null || currentDate > maxDate) {
                            maxDate = currentDate;
                        }
                    }
                });
            });
            return maxDate;
        },
    },
    mounted () {
    },
};
</script>

<style lang="scss">
.business {
	color: #444444;
	font-size: 12px;
	margin-bottom: 0 !important;
}

div[campaign-card]{
    user-select: none;
    border-radius: 4px;
    border: solid rgba(0,0,0,0.12) 1px;

	.majFL{
		&::first-letter{
			text-transform: uppercase;
		}
	}

	[tooltip]{
		position: relative;

		&::before{
			content: attr(tooltip);
			position: absolute;
			transform: translateY(calc(-100% - 5px));
			background: rgb(128, 128, 128);
			z-index: 4;
			font-size: 12px;
			padding: 4px;
			border-radius: 4px;
			scale: 0;
			transition: scale 200ms ease-in-out;
			color: #fff;
		}

		&:hover::before{
			scale: 1;
		}
	}
}
	.red-text {
		color: red
	}
	.lineHeight {
		line-height:normal;
	align-self: last baseline ;
	}

</style>
